import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Privacy from '../components/Privacy'
const Privacypage = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Privacy></Privacy>
            <Footer></Footer>
        </div>
    )
}

export default Privacypage
