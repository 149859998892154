import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import Shipping from '../components/Shipping'

const Shippingpage = () => {
    return (
        <div>
            <Navbar></Navbar>
            <Shipping></Shipping>
            <Footer></Footer>

        </div>
    )
}

export default Shippingpage
